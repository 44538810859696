import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Php_top from "../images/php/php_top.png"
import Process__Step_Sr1 from "../images/shopify/def_approach.png"
import Process__Step_Sr2 from "../images/shopify/best_aaproch.png"
import Process__Step_Sr3 from "../images/shopify/consulting.png"
import Php_custom from "../images/php/custom-php.jpg"
import Php_ecommerce from "../images/php/ss.png"
import Migration_php from "../images/php/migartion_php.jpg"
import Php_api from "../images/php/api.jpg"
import {Helmet} from "react-helmet/es/Helmet";





export default class Container extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Contact Us | Get in Touch with Cueblocks </title>
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
          <meta charSet="utf-8"/>
          <link rel="canonical" href="https://www.cueblocks.com/contact.php"/>
          <meta name="description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/contact.php"/>
          <meta property="og:title" content="Contact Us | Get in Touch with Cueblocks"/>
          <meta property="og:description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
          <meta name="twitter:title" content="Contact Us | Get in Touch with Cueblocks"/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <article className="static_page php-page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <h2 className="heading_main">PHP</h2>
              <div className="ser_sec_row_lt wdt-40 pd_right_40">
              
                <div className="ser_sec_row_lt_text">
                  <p>
                    A platform that offers compatibility with other technologies, universal browser support, and database integration, sounds like a
                     developer’s dream, doesn’t it? For our team of highly-skilled professionals, PHP offers much more than just the ease of functionality. 
                  <br /><br />
                    A programming language like PHP with its diverse features helps us to create engaging and interactive web applications that meet the most 
                    basic requirements of your organizational structure, objectives, as well as workflow management with a targeted approach.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center">
                <img
                  src={Php_top}
                  alt="Php"
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">our approach</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1 }
                        alt="DEVELOPMENT ENVIRONMENT"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>DEVELOPMENT ENVIRONMENT</h3>
                    <p>
                      We use the reliable Version Control Systems and Staging environments to ensure that a properly vetted code makes it to your live site,
                       giving you a high-quality website that meets a global standard.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="DEFINED APPROACH"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>DEFINED APPROACH</h3>
                    <p>
                      Our team of proficient experts devises a crisp strategy to get your website up and running like clockwork fueled by streamlined communication that is 
                    essential for the smooth development of any website.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="CONSULTATIVE APPROACH"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>CONSULTATIVE APPROACH</h3>
                    <p>
                      Our ability to think out of the box merges with our experience in the eCommerce industry to give you the right guidance for your website,
                       keeping the basic concepts of what’s trending in mind. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40 ">
                  <figure>
                    <span className="highlight-span">php/join switch</span>
                    <img
                      src={Php_custom}
                      alt="CUSTOM PHP DEVELOPMENT"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    Custom PHP <br/>Development
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      A truly user-friendly application is not just highly secure & perceptive, but it is also extremely high-performance. With our adept knowledge of 
                      the PHP codes, we integrate your existing applications to provide added functionalities to your application that makes customization as smooth and 
                      efficient as possible. 
                       <br />
                      <br />
                  
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_40 right shadow2">
                  <figure>
                    <img
                      src={Php_ecommerce}
                      alt="PHP for eCommerce"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_20">
                  <h2 className="heading_main">
                   PHP for eCommerce
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      To provide your customers with a seamless shopping experience, your eStore needs to be dynamic & robust. Following the best PHP practices
                       for your eCommerce site, our team of highly skilled developers provides you with a feature-rich and practical eStore that’s not just completely 
                       secure but also offers fast data processing as well as increased efficiency and usability.  
                      <br />
                      <br />
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className="btn main_cta" >
                   Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

    
          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Migration_php}
                      alt="Migration to PHP"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_20">
                  <h2 className="heading_main">
                   Migration to PHP
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      While migrating your website, ensuring that your preferred coding language can take care of the most basic aspects like security, testing, planning,
                       and deployment is pivotal, especially if you want your customers to have a smooth experience. Our team of experts, with over a decade of experience,
                        make sure that your website’s data and server are safely migrated to PHP, adhering to the best industry practices at each stage.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img border-section last_product">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_20 right">
                  <figure>
                    <img
                      src={Php_api}
                      alt="REST API Development"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">REST API Development</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      The growing demand for dynamic mobile & web applications has furnished the need for a well-organized code that offers secure authentication. 
                      This is where our team of professionals steps in to deliver a mobile or web app that is synchronized by RESTful APIs, thereby allowing you the
                       liberty to run multiple versions of the same application simultaneously.
                       <br />
                      <br />
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

       </article>
      </Layout>
    )
  }
}
